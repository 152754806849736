import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { localStorageClear } from 'shared/common';
import { useMsal } from '@azure/msal-react';
let alertConfirm;

const AlertModal = () => {
  const { instance } = useMsal();
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  window.antdAlert = (title, msg) => {
    setTitle(title);
    setMessage(msg);
    setVisible(true);
    return new Promise((resolve) => {
      alertConfirm = resolve;
    });
  };
  window.logout = (
    title = '已登出',
    msg = '',
    iconType = 'success',
    popNotify = true
  ) => {
    localStorageClear();
    instance.setActiveAccount(null);
    if (popNotify) {
      window.antdNotify(title, msg, iconType);
    }
    window.navigate('/Login');
  };
  window.navigate = (url) => {
    navigate(url);
  };

  const handleOk = () => {
    alertConfirm(true);
    setVisible(false);
  };

  const handleCancel = () => {
    alertConfirm(false);
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={9999}
      footer={[
        <Button key="close" type="primary" onClick={handleCancel}>
          關閉
        </Button>,
      ]}>
      {message}
    </Modal>
  );
};

export default AlertModal;
