import React from "react";
import { Routes, Route } from "react-router-dom";
import ProjectStat from "pages/projectStat/ProjectStat";

const ProjectStatRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectStat />} />
    </Routes>
  );
};

export default ProjectStatRouter;
