import React from "react";
import { Routes, Route } from "react-router-dom";
import MarketingCampaign from "pages/marketingCampaign/MarketingCampaign";

const MarketingCampaignRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MarketingCampaign />} />
    </Routes>
  );
};

export default MarketingCampaignRouter;
