import React from "react";
import { Routes, Route } from "react-router-dom";
import TargetCustomer from "pages/targetCustomer/TargetCustomer";

const TargetCustomerRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TargetCustomer />} />
    </Routes>
  );
};

export default TargetCustomerRouter;
