import React from "react";
import { Routes, Route } from "react-router-dom";
import CampaignProject from "pages/campaignProject/CampaignProject";

const CampaignProjectRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CampaignProject />} />
    </Routes>
  );
};

export default CampaignProjectRouter;
