import React from 'react';
import _axios from 'axios';
import moment from 'moment-timezone';
import { jwtDecode } from 'jwt-decode';
import { loginRequest } from '../authConfig';
class HttpHandler {
  constructor(
    instance,
    checkToken = true,
    baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000'
  ) {
    this.instance = instance;
    this.checkToken = checkToken;
    this.baseURL = baseURL;
  }
  axios = (baseURL) => {
    const instance = _axios.create({
      baseURL: this.baseURL,
      timeout: 5000000,
    });
    return instance;
  };

  navigateTo(url) {
    window.navigate(url);
  }

  // post method
  async post(url, data, contentType = 'application/json') {
    let headers = await this.getHeader(contentType);
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .post(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          window.antdNotify('', '登入時效已過期, 請重新登入', 'success');
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .finally(() => {});
  }

  // put method
  async put(url, data, contentType = 'application/json') {
    let headers = await this.getHeader(contentType);
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .put(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          window.antdNotify('', '登入時效已過期, 請重新登入', 'success');
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .finally(() => {});
  }

  // get method
  async get(url) {
    let headers = await this.getHeader('application/json');
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .get(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // 轉換時間為台灣時區
          if (Array.isArray(response.data.data)) {
            response.data.data.forEach(async (item) => {
              // 資料要呈現在DataTalbe時, 轉換為台灣時區
              if (item.create_time) {
                item.create_time = await this.UTCToTaiwanZone(item.create_time);
              }
              if (item.update_time) {
                item.update_time = await this.UTCToTaiwanZone(item.update_time);
              }
              if (item.cancel_time) {
                item.cancel_time = await this.UTCToTaiwanZone(item.cancel_time);
              }
            });
          }
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          window.antdNotify('', '登入時效已過期, 請重新登入', 'success');
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  // delete method
  async delete(url) {
    let headers = await this.getHeader('application/json');
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .delete(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          window.antdNotify('', '登入時效已過期, 請重新登入', 'success');
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  async fileDownload(
    url,
    fileExtension,
    fileName = 'output',
    withoutSpinner = false
  ) {
    let headers = await this.getHeader('');

    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .get(url, { headers: headers })
      .then((response) => {
        const blob = new Blob([response.data]);
        const downloadUrl = window.URL.createObjectURL(blob); // create url from blob
        const fileLink = document.createElement('a'); // create link for file
        fileLink.href = downloadUrl;
        fileLink.download = fileName + '.' + fileExtension; // download filename
        document.body.appendChild(fileLink); // append file link to download
        fileLink.click();
        fileLink.remove(); // remove file link after click
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
      });
  }

  async getHeader(content_type = 'application/json') {
    let headers;

    if (content_type !== '') {
      headers = {
        'Content-Type': content_type,
      };
    } else {
      headers = {};
    }
    // 未從Login頁面登入且沒有activeAccount時則導向登入頁面
    if (
      !localStorage.getItem('userLoggedIn') &&
      !this.instance.getActiveAccount()
    ) {
      window.logout('登入逾時', '請重新登入', 'fail');
      return;
    }
    const activeAccount = await this.awaitToGetActiveAccount();
    // 確認Token
    if (this.checkToken) {
      var keys = Object.keys(localStorage).filter((key) =>
        key.includes(`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`)
      );

      // 沒有accessToken
      if (keys.length === 0) {
        await this.storeTokenToLocalStorage(activeAccount);
        keys = Object.keys(localStorage).filter((key) =>
          key.includes(`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`)
        );
      }
      // accessToken過期
      var jwt = JSON.parse(localStorage.getItem(keys[0]))['secret'];
      const decodeJWT = jwtDecode(jwt);
      if (moment().unix() > decodeJWT['exp']) {
        await this.storeTokenToLocalStorage(activeAccount);
        jwt = JSON.parse(localStorage.getItem(keys[0]))['secret'];
      }

      headers.authorization = 'Bearer ' + jwt;
    }
    localStorage.removeItem('userLoggedIn');
    return headers;
  }

  // UTC轉換為台灣時區
  async UTCToTaiwanZone(time) {
    // 時間結尾需要加上'Z'才能被視為UTC時間
    time = new Date(time + 'Z').toLocaleString('zh-TW', {
      timeZone: 'Asia/Taipei',
    });
    return time;
  }

  // 透過activeAccount取得accessToken並存入localStorage
  async storeTokenToLocalStorage(activeAccount) {
    try {
      const tokenRequest = {
        scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`], // 不需要特別設定expose api, 直接用default即可
        account: activeAccount,
      };
      // MSAL使用acquireTokenSilent會自動更新到localStorage
      await this.instance.acquireTokenSilent(tokenRequest);
    } catch (error) {
      console.log(error);
    }
  }
  // 取得activeAccount
  async awaitToGetActiveAccount() {
    while (true) {
      const activeAccount = this.instance.getActiveAccount();
      if (activeAccount !== null) {
        return activeAccount;
      } else {
        console.log('等待instance');
        await new Promise((resolve) => setTimeout(resolve, 500)); // 每0.5秒檢查一次
      }
    }
  }
}

export { HttpHandler };
