import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SystemRouter from './home/SystemRouter';
import MainEX from '../pages/homeDefault/MainEX';
import MarketingCampaignRouter from './home/MarketingCampaignRouter';
import CampaignProjectRouter from './home/CampaignProjectRouter';
import ProjectStatRouter from './home/ProjectStatRouter';
import TargetCustomerRouter from './home/TargetCustomerRouter';

const HomeRouter = () => {
  return (
    <Routes>
      <Route
        path="/MarketingCampaign/*"
        element={<MarketingCampaignRouter />}></Route>
      <Route
        path="/CampaignProject/*"
        element={<CampaignProjectRouter />}></Route>
      <Route path="/ProjectStat/*" element={<ProjectStatRouter />}></Route>
      <Route
        path="/TargetCustomer/*"
        element={<TargetCustomerRouter />}></Route>
      <Route path="/System/*" element={<SystemRouter />}></Route>
      <Route path="/*" element={<MainEX />}></Route>
    </Routes>
  );
};

export default HomeRouter;
