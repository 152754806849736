import React from "react";
import { Routes, Route } from "react-router-dom";
import UserMaintenance from "pages/system/UserMaintenance";
import RoleMaintenance from "pages/system/RoleMaintenance";

const SystemRouter = () => {
  return (
    <Routes>
      <Route path="/UserMaintenance" element={<UserMaintenance />} />
      <Route path="/RoleMaintenance" element={<RoleMaintenance />} />
    </Routes>
  );
};

export default SystemRouter;
