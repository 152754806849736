import { Layout, Space, Menu, Flex, Button, Dropdown } from 'antd';
import { useState, React, useEffect, useContext } from 'react';
import {
  LogoutOutlined,
  DownOutlined,
  UserOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import HomeRouter from './../../router/HomeRouter';
import { getMenuItems } from './home/menuItem';
import { HttpHandlerContext } from '../../shared/MsalHttpHandler';
import hotaiLogo from './../../images/hotaiLogo.png';
import { jwtDecode } from 'jwt-decode';
import { CryptoHandler } from '../../shared/cryptoHandler';

const Home = () => {
  const httpHandler = useContext(HttpHandlerContext);
  const [menuLoading, setMenuLoading] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const [userName, setUserName] = useState('');
  const items = [
    {
      label: 'Setting',
      key: 'Setting',
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      label: <a onClick={() => window.logout()}>登出</a>,
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ];

  const updateMenu = async () => {
    setMenuLoading(true);
    const menuItems = await getMenuItems(httpHandler);
    await getNameFromTokenAndStore();
    setMenuItems(menuItems);
    setMenuLoading(false);
  };
  const getNameFromTokenAndStore = async () => {
    const keys = Object.keys(localStorage).filter((key) =>
      key.includes(`idtoken`)
    );
    const idToken = JSON.parse(localStorage.getItem(keys[0]))['secret'];
    const decodedToken = jwtDecode(idToken);
    // 使用加密函數加密數據
    setUserName(decodedToken.name);
  };

  useEffect(() => {
    updateMenu();
  }, []);

  const { Header, Content, Footer, Sider } = Layout;
  const [siderCollapse, setSiderCollapse] = useState(false);

  return (
    <Layout className="home" theme="light">
      <Sider
        collapsible="true"
        collapsed={siderCollapse}
        onCollapse={() => {
          setSiderCollapse(!siderCollapse);
        }}
        theme="dark">
        {menuLoading ? (
          <SyncOutlined className="menu-loading-icon" spin />
        ) : (
          <Menu
            theme="dark"
            mode="inline"
            // openKeys={[2]}
            items={menuItems}></Menu>
        )}
      </Sider>
      <Layout>
        <Header className="home-header">
          <div className="companyname">
            <Space>
              <img src={hotaiLogo} alt="Logo" className="h-8" />
              個人化廣宣管理平台
            </Space>
          </div>
          <div>
            <Space>
              <Dropdown menu={{ items }} placement="bottomRight">
                <Button>
                  <UserOutlined />
                  {userName}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </div>
        </Header>
        <Content className="home-content">
          <HomeRouter />
        </Content>
        <Footer>
          <Flex justify="space-between">
            <span></span>
            <span className="login-info">
              <div>
                <span>版本:&nbsp;{process.env.REACT_APP_VERSION}</span>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span>登入時間:&nbsp;{localStorage.getItem('loginTime')}</span>
              </div>
            </span>
          </Flex>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Home;
