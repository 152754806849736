import { React, useState, useEffect, useContext, useRef } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Typography,
  Select,
  Upload,
  Image,
  DatePicker,
  Tabs,
} from 'antd';
import { SearchOutlined, QuestionCircleOutlined, BarChartOutlined, AreaChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { HttpHandlerContext } from '../../shared/MsalHttpHandler';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import create from '@ant-design/icons/lib/components/IconFont';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { type } from '@testing-library/user-event/dist/type';
import { icons } from 'antd/es/image/PreviewGroup';
const { Paragraph } = Typography;

let eventMap = []

const typeMap = {
  0: '全部',
  1: '公告',
  2: '品牌活動',
  3: '純活動曝光',
  4: '異業合作',
  5: '新服務/機制推廣',
  6: '會員拉新活動',
  7: '會員經營活動',
  8: '營運分類',
};
const channelMap = {
  0: '全部',
  1: '推播',
  2: 'EDM',
  3: 'SMS',
};
const chartchannel = {
  1: '推播',
  2: 'EDM',
  3: 'SMS',
};
const departmentMap = {
  0: '全部',
  1: 'iRent',
  2: '門市',
  3: '專車',
  4: '停車場',
};
const data = [
  { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];
const pieData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
// 父組件
const ProjectStat = () => {
  const httpHandler = useContext(HttpHandlerContext);
  const [searchForm] = Form.useForm();
  const [chartForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [pickid, setPickid] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isChannelDisabled, setIsChannelDisabled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [chart_data, setchartdata] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    const { type, name, department, channel } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);

    httpHandler
      .get(
        `/stat/list?ids=${pickid}&name=${name}&department=${department}&type=${type}&channel=${channel}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.data.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        chartForm.resetFields();
        setIsChannelDisabled(true);
        setIsButtonDisabled(true);
        setchartdata([]);
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '確定刪除？',
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn loading={deleteLoading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        setDeleteLoading(true);
        return httpHandler
          .delete(`/custom_lounge/${id}`)
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', '刪除成功', 'success');
              searchCustomLoungeItem();
            } else {
              window.antdNotify('錯誤', response.data.message, 'fail');
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      },
    });
  };

  const linechart = () => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '折線圖',
      width: 600,
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </LineChart>
          <br></br>
          <CancelBtn />
          <OkBtn loading={loading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
    });
  };

  const circlechart = () => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '圓餅圖',
      width: 600,
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <PieChart width={400} height={400}>
            <Pie
              data={pieData}
              cx={200}
              cy={200}
              labelLine={false}
              label
              outerRadius={80}
              fill="#8884d8"
              dataKey="value">
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <br></br>
          <CancelBtn />
          <OkBtn loading={loading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
    });
  };

  const rowbarchart = () => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '橫向長條圖',
      width: 600,
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <BarChart
            width={500}
            height={300}
            data={data}
            layout="vertical"
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8884d8" />
            <Bar dataKey="uv" fill="#82ca9d" />
          </BarChart>
          <br></br>
          <CancelBtn />
          <OkBtn loading={loading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
    });
  };

  const colbarchart = (record) => {
    const new_data = [
      { name: "駕照異常查驗", 發送數量: record.licence_error_count },
      { name: "超過接觸次數", 發送數量: record.contact_limit_count },
      { name: "有效筆數", 發送數量: record.target_count },
      { name: "黑名單筆數", 發送數量: record.blacklist_count },
      { name: "會員積分<50筆數", 發送數量: record.score_count },
      { name: "測試筆數", 發送數量: record.test_count },
      { name: "發送總數", 發送數量: record.total_count }];
    console.log(record);
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: `${record.project_name}`,
      width: 600,
      icon: <BarChartOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <BarChart
            width={500}
            height={300}
            data={new_data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="發送數量" fill="#8884d8" />
          </BarChart>
          <br></br>
          <CancelBtn />
          <OkBtn loading={loading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
    });
  };

  const showchart = (record) => {
    const new_data = [
      { name: "駕照異常查驗", 發送數量: record.licence_error_count },
      { name: "超過接觸次數", 發送數量: record.contact_limit_count },
      { name: "有效筆數", 發送數量: record.target_count },
      { name: "黑名單筆數", 發送數量: record.blacklist_count },
      { name: "會員積分<50筆數", 發送數量: record.score_count },
      { name: "測試筆數", 發送數量: record.test_count },
      { name: "發送總數", 發送數量: record.total_count }];

      Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: `${record.project_name}`,
      width: 1000,
      icon: <BarChartOutlined />,
      footer: (_, { CancelBtn }) => (
        <>
          <BarChart
            width={900}
            height={400}
            data={new_data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[0, 'auto']} // 确保从 0 开始
              allowDecimals={false} // 禁止小数点
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="發送數量" fill="#8884d8" />
          </BarChart>
          <br></br>
          <CancelBtn />
        </>
      ),
      cancelText: '取消',
    });
  };

  const searchCustomLoungeItem = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
    getallevent();
  };

  useEffect(() => {
    // 預設表單值
    searchForm.setFieldsValue({
      name: '',
      department: '0',
      type: '0',
      channel: '0'
    });
    // 進入畫面時執行一次搜尋
    searchCustomLoungeItem();
  }, []);

  const getallevent = () => {
    const { current, pageSize } = tableParams.pagination;

    httpHandler
      .get(
        `/marketing_campaign/list?ids=-1&name=&department=0&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        const names = response.data.map((item) => item.name);
        const ids = response.data.map((item) => item.id);
        eventMap = names.reduce((acc, name, index) => {
          acc[ids[index]] = name;
          return acc;
        }, {});
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  // 搜尋組件
  const SearchBar = () => {
    const [options, setOptions] = useState(Object.keys(eventMap));
    const [latest, setLatest] = useState("");

    const handleSearch = (input) => {
      // 動態取得當前選單
      const filteredOptions = Object.keys(eventMap).filter((key) =>
        eventMap[key].toLowerCase().includes(input.toLowerCase())
      );
      setLatest(input);
      setOptions(filteredOptions);
    };

    const nameChange = (value) => {
      if (value === undefined) {
        setPickid(-1);
        searchForm.setFieldsValue({name: ""})
      }
      else {
        setPickid(value);
        searchForm.setFieldsValue({name: eventMap[value]})
      }
    };

    const handleBlur = () => {
      const values = searchForm.getFieldsValue();

      // 選單長度不為0 && 當前輸入框有文字，失焦時自動選擇選單的第一個選項
      if (options.length !== 0 && latest !== "") {
        setPickid(options[options.length - 1]);
        searchForm.setFieldsValue({name: eventMap[options[options.length - 1]]});
      }
      // 如果沒有選擇選項 && 選單長度不為0 && 當前輸入框沒有文字
      else if ( (values.name === "" && options.length !== 0 && latest === "") ||
      // 選單長度為0 && 當前輸入框有文字
                (options.length === 0 && latest !== "")) {
        setPickid(-1);
        searchForm.setFieldsValue({name: ""});
      }
    };

    return (
      <>
        <Form
          form={searchForm}
          name="searchForm"
          onFinish={searchCustomLoungeItem}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label="活動名稱" name="name">
                <Select
                  showSearch
                  allowClear
                  onChange={nameChange}
                  onBlur={handleBlur}
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {options.slice().reverse().map((key) => (
                    <Select.Option key={key} value={key}>
                      {eventMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="業務別" name="department">
                <Select>
                  {Object.keys(departmentMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {departmentMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="活動類型" name="type">
                <Select>
                  {Object.keys(typeMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {typeMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="行銷管道" name="channel">
                <Select>
                  {Object.keys(channelMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {channelMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  // 表格組件
  const DataTable = () => {
    const [isReadMoreModalVisible, setIsReadMoreModalVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const readMore = (text) => {
      const maxLength = 10; // 最大顯示的字元數
      if (text.length > maxLength) {
        return (
          <span>
            {text.substring(0, maxLength)}...
            <a
              onClick={() => {
                setCurrentText(text);
                setIsReadMoreModalVisible(true);
              }}>
              閱讀更多
            </a>
          </span>
        );
      }
      return text;
    };
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '活動編號',
        dataIndex: 'campaign_id',
      },
      {
        title: '活動名稱',
        dataIndex: 'name',
      },
      {
        title: '操作',
        dataIndex: '',
        render: (text, record, index) => {
          return (
            <>
              <Button
                icon={<BarChartOutlined />}
                type="primary"
                className="button--chart"
                style={{ marginRight: 16 }}
                onClick={() => {
                  showchart(record);
                }}>
                圖表
              </Button>
            </>
          );
        },
      },
      {
        title: '行銷通路',
        dataIndex: 'channel',
        render: (text) => {
          return channelMap[text];
        },
      },
      {
        title: '方案名稱',
        dataIndex: 'project_name',
      },
      {
        title: '發送時間',
        dataIndex: 'create_time',
      },
      {
        title: '駕照查驗異常',
        dataIndex: 'licence_error_count',
      },
      {
        title: '超過接觸次數',
        dataIndex: 'contact_limit_count',
      },
      {
        title: '有效筆數',
        dataIndex: 'target_count',
      },
      {
        title: '黑名單筆數',
        dataIndex: 'blacklist_count',
      },
      {
        title: '會員積分<50筆數',
        dataIndex: 'score_count',
      },
      {
        title: '測試筆數',
        dataIndex: 'test_count',
      },
      {
        title: '發送總數',
        dataIndex: 'total_count',
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title="完整內容"
          open={isReadMoreModalVisible}
          onOk={() => setIsReadMoreModalVisible(false)}
          onCancel={() => setIsReadMoreModalVisible(false)}>
          <Typography>
            <Paragraph>
              <pre>{currentText}</pre>
            </Paragraph>
          </Typography>
        </Modal>
      </>
    );
  };

  const ChartTable = () => {
    const { RangePicker } = DatePicker;
    const [dates, setDates] = useState([]);
    const [value, setValue] = useState([]);

    const chartChange = () => {
      setchartdata([]);
      const data = chartForm.getFieldsValue();
      const range_0_y = data.date[0].year();
      const range_1_y = data.date[1].year();
      const range_0_m = data.date[0].month() + 1;
      const range_1_m = data.date[1].month() + 1;
      const range_0_d = data.date[0].date();
      const range_1_d = data.date[1].date();
      const count_date = (range_1_m - range_0_m) * 30 + range_1_d;

      const result = Object.values(
        tableData.reduce((acc, item) => {
          // 判斷該筆資料的行銷管道跟選單選取的相同
          if (`${item.channel}` === data.chart_channel) {
            const d = dayjs(item.create_time, 'YYYY/M/D h:mm:ss');
            const new_date = (d.month() + 1 - range_0_m) * 30 + d.date();

            // 判斷該筆方案的發送時間有沒有在選取的起訖日範圍之內
            if ( (d.year() >= range_0_y && d.year() <= range_1_y) &&
                 (d.month()+1 >= range_0_m && d.month()+1 <= range_1_m) ) {

              // 同月份可以直接判斷日期
              if (range_0_m === range_1_m && (d.date() >= range_0_d && d.date() <= range_1_d)) {
                if (!acc[item.name]) {
                  acc[item.name] = { name: item.name, total: 0 };
                }
                acc[item.name].total += item.total_count;
              }

              // 月份不同需要用心的日期進行運算
              if (range_0_m !== range_1_m && (new_date >= range_0_d && new_date <= count_date)) {
                if (!acc[item.name]) {
                  acc[item.name] = { name: item.name, total: 0 };
                }
                acc[item.name].total += item.total_count;
              }
            }
          }
          return acc;
        }, {})
      );

      setchartdata(result);
    };

    // 用来限制可选择的日期范围
    const disabledDate = (current) => {
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'months') >= 3;
      const tooEarly = dates[1] && dates[1].diff(current, 'months') >= 3;
      return tooEarly || tooLate;
    };
    return(
      <>
        <br></br>
        <Form
          form={chartForm}
          name="chartForm">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="起迄日期" name="date">
              <RangePicker
                format="YYYY-MM-DD"
                value={value}
                disabledDate={disabledDate}
                onCalendarChange={(val) => {
                  setDates(val);
                  if (val && val[0] && val[1]) {
                    setIsChannelDisabled(false); // 启用行销管道
                  } else {
                    setIsChannelDisabled(true);
                    setIsButtonDisabled(true); // 重置按钮禁用状态
                    chartForm.resetFields(['chart_channel']);
                  }
                }}
                onChange={(val) => {
                  setValue(val);
                  setDates([]);
                  setchartdata([]);
                }}
              />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="行銷管道" name="chart_channel">
                  <Select
                    disabled={isChannelDisabled}
                    onChange={() => setIsButtonDisabled(false)} // 启用按钮
                  >
                  {Object.keys(chartchannel).map((key) => (
                    <Select.Option key={key} value={key}>
                      {chartchannel[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} className="button-group">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--chartsearch"
                disabled={isButtonDisabled} // 按钮是否禁用
                onClick={chartChange}
                >
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
        <br></br>
        <div style={{ display: 'flex', justifyContent: 'center', marginLeft:-50}}>
          <BarChart
            width={1100}
            height={580}
            data={chart_data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[0, 'auto']} // 确保从 0 开始
              allowDecimals={false} // 禁止小数点
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="total" fill="#8884d8" />
          </BarChart>
        </div>
      </>
    );
  };

  const tableitems = [
    {
      key: '1',
      label: '方案表格',
      icon:<UnorderedListOutlined />,
      children: <DataTable />,
    },
    {
      key: '2',
      label: '方案圖示',
      icon:<AreaChartOutlined />,
      children: <ChartTable />,
    }
  ];

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <Tabs defaultActiveKey='1' items={tableitems} />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <CustomLoungeModal
          searchCustomLoungeItem={searchCustomLoungeItem}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
        />
      ),
    },
  ];

  return (
    <div>
      <PageHeader functionName={'project_stat'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};
// 共用的Modal
const CustomLoungeModal = (props) => {
  const httpHandler = useContext(HttpHandlerContext);
  const {
    searchCustomLoungeItem,
    isModalVisible,
    setIsModalVisible,
    modalForm,
    isNew,
  } = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleImageUpload = async (postData) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        postData['image_base64'] = reader.result;
        console.log('postData: ', postData);
        resolve();
      };
      reader.onerror = reject;
      reader.readAsDataURL(fileList[0]['originFileObj']);
    });
  };
  const handleModalSubmit = async () => {
    try {
      const values = await modalForm.validateFields();
      const confirmed = await window.antdConfirm(
        `確定${isNew ? '新增' : '修改'}品項嗎？`
      );
      if (!confirmed) return;
      setLoading(true);
      // 根據isNew判斷是新增還是修改
      let requestPromise;
      const msg = isNew ? '新增成功' : '修改成功';
      let postData = {
        create_time: values.create_time,
        target_count: values.target_count,
        blacklist_count: values.blacklist_count,
        less_point_count: values.less_point_count,
        test_count: values.test_count,
        total_count: values.total_count,
      };
      // 如果有更新圖檔，則傳送到後端
      if (fileList.length > 0) {
        await handleImageUpload(postData);
      }
      if (isNew === true) {
        requestPromise = httpHandler.post('/custom_lounge/', postData);
      } else {
        requestPromise = httpHandler.put(
          `/custom_lounge/${values.id}`,
          postData
        );
      }

      requestPromise
        .then((response) => {
          if (response.success) {
            window.antdNotify('成功', msg, 'success');
            setIsModalVisible(false);
            searchCustomLoungeItem();
            modalForm.resetFields();
            setLoading(false);
          } else {
            setLoading(false);
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .finally(() => {
          setFileList([]);
          setLoading(false);
        });
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = () => {
    modalForm.resetFields();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title={isNew ? '新增品項' : '修改品項'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}
      okButtonProps={{ loading: loading }}>
      <ModalForm
        modalForm={modalForm}
        isNew={isNew}
        fileList={fileList}
        setFileList={setFileList}
      />
    </Modal>
  );
};
// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm, isNew, fileList, setFileList } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };
  // 上傳前檢查
  const beforeUpload = (file) => {
    // 檢查檔案類型
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      window.antdNotify('錯誤', '只能上傳圖片檔案', 'fail');
      return Upload.LIST_IGNORE;
    }
    // 檢查檔案大小
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      window.antdNotify('錯誤', '圖片大小不能超過10MB', 'fail');
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  // 上傳圖片轉base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  // 預覽圖片
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewTitle(file.name);
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  // 取消預覽
  const handleCancel = () => setPreviewVisible(false);
  return (
    <Form form={modalForm}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="上傳日期"
        name="upload_date"
        rules={[{ required: true, message: '請選取上傳日期' }]}>
        <DatePicker
          defaultValue={dayjs()}
          disabledDate={(current) =>
            current && current.isBefore(dayjs().startOf('day'))
          }
        />
      </Form.Item>
      <Form.Item
        label="有效筆數"
        name="target_count"
        rules={[{ required: true, message: '請輸入有效筆數!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="黑名單筆數"
        name="blacklist_count"
        rules={[{ required: true, message: '請輸入黑名單筆數!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="會員積分<50筆數"
        name="less_point_count"
        rules={[{ required: true, message: '請輸入會員積分<50筆數!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="測試筆數"
        name="test_count"
        rules={[{ required: true, message: '請輸入測試筆數!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="發送總數"
        name="total_count"
        rules={[{ required: true, message: '請輸入發送總數!' }]}>
        <Input />
      </Form.Item>
      {/* 預覽Modal */}
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form>
  );
};

// 驗證props
CustomLoungeModal.propTypes = {
  searchCustomLoungeItem: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
};
ModalForm.propTypes = {
  modalForm: PropTypes.object,
};
export default ProjectStat;
