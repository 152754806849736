import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './main.scss';
import AppRouter from './router/AppRouter';
import ConfirmModal from './utility/ConfirmModal';
import AlertModal from './utility/AlertModal';
import AntdNotification from './utility/AntdNotification';
import { MsalProvider } from '@azure/msal-react';
import { HttpHandler } from './shared/httpHandler';
import { HttpHandlerContext } from './shared/MsalHttpHandler';

const App = ({ instance }) => {
  const httpHandler = new HttpHandler(instance);
  return (
    <MsalProvider instance={instance}>
      <HttpHandlerContext.Provider value={httpHandler}>
        {/* browserRouter 是為了讓頁面可以用路徑切換 */}
        <BrowserRouter>
          <AppRouter />
          <ConfirmModal />
          <AlertModal />
          <AntdNotification />
        </BrowserRouter>
      </HttpHandlerContext.Provider>
    </MsalProvider>
  );
};

export default App;
