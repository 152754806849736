import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/basic/Home";

import Login from "../pages/basic/Login";
import Error401 from "../pages/errorPages/Error401";
import Error403 from "../pages/errorPages/Error403";
import Error404 from "../pages/errorPages/Error404";
import Error500 from "../pages/errorPages/Error500";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="Login" element={<Login />}></Route>
      <Route path="home/*" element={<Home />}></Route>
      <Route path="error404" element={<Error404 />}></Route>
      <Route path="error403" element={<Error403 />}></Route>
      <Route path="error401" element={<Error401 />}></Route>
      <Route path="error500" element={<Error500 />}></Route>
      <Route path="*" element={<Home />}></Route>
    </Routes>
  );
};

export default AppRouter;
