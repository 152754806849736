import React from 'react';
import { Result, Button } from 'antd';

const Error401 = () => {
  const handleLogout = () => {
    // 清除 localStorage
    localStorage.clear();
    // 導向登入頁面
    window.navigate('/Login');
  };

  return (
    <Result
      status="403"
      title="401"
      subTitle="對不起，您的權限不足，無法訪問此頁面。"
      extra={
        <Button type="primary" onClick={handleLogout}>
          返回登入頁面
        </Button>
      }
    />
  );
};

export default Error401;
