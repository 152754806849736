import { React, useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Typography,
  Select,
  DatePicker,
  Upload,
  Descriptions,
  Divider,
  Space,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { HttpHandlerContext } from '../../shared/MsalHttpHandler';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import FormItem from 'antd/es/form/FormItem';
import { render } from '@testing-library/react';
const { Paragraph } = Typography;

const typeMap = {
  1: '公告',
  2: '品牌活動',
  3: '純活動曝光',
  4: '異業合作',
  5: '新服務/機制推廣',
  6: '會員拉新活動',
  7: '會員經營活動',
  8: '營運分類',
};
const statusMap = {
  1: '尚未開始',
  2: '進行中',
  98: '已駁回',
  99: '已結案',
};
const customerMap = ['高消費用戶', '月租客戶', '一般用戶', '良好用戶'];
let eventMap = [];
let eventStatus = [];

// 父組件
const TargetCustomer = () => {
  const httpHandler = useContext(HttpHandlerContext);
  const [searchForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pickid, setPickid] = useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const fileList = useRef(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    getallevent();
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    const { name } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);
    let searchstr = ``;

    if (name === '') {
      searchstr = `/target_customer/list?ids=-1&name=${name}&page=${current}&page_size=${pageSize}&is_all=false&fuzzy=true`;
    } else {
      searchstr = `/target_customer/list?ids=${pickid}&name=${name}&page=${current}&page_size=${pageSize}&is_all=false&fuzzy=true`;
    }

    httpHandler
      .get(searchstr)
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          //window.antdNotify("錯誤", response.message, "fail");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const get_custom_data = () => {
    httpHandler
      .fileDownload(`/target_customer/custom_data`, 'csv')
      .then((response) => {
        console.log(response.message);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '確定刪除客群？',
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <div style={{ marginBottom: 10 }}>
            <h3 style={{ textAlign: 'left' }}>※ 方案內的客群將一併刪除！</h3>
          </div>
          <CancelBtn />
          <OkBtn loading={loading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        setLoading(true);
        return httpHandler
          .delete(`/target_customer/${id}`)
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', '刪除客群成功', 'success');
            } else {
              window.antdNotify('錯誤', response.message, 'fail');
            }
            updateData();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const searchMarketingCampaignItem = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
    getallevent();
  };

  useEffect(() => {
    // 預設搜尋條件
    searchForm.setFieldsValue({
      name: '',
      department: '0',
    });
    // 進入畫面時執行一次搜尋
    searchMarketingCampaignItem();
  }, []);

  const getallevent = () => {
    const { current, pageSize } = tableParams.pagination;

    httpHandler
      .get(
        `/marketing_campaign/list?ids=-1&name=&department=0&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        const names = response.data.map((item) => item.name);
        const status = response.data.map((item) => item.status);
        const ids = response.data.map((item) => item.id);
        eventMap = names.reduce((acc, name, index) => {
          acc[ids[index]] = name;
          return acc;
        }, {});

        eventStatus = status.reduce((acc, s, index) => {
          acc[ids[index]] = s;
          return acc;
        }, {});
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  // 搜尋組件
  const SearchBar = () => {
    const [options, setOptions] = useState(Object.keys(eventMap));
    const [latest, setLatest] = useState('');

    const handleSearch = (input) => {
      // 動態取得當前選單
      const filteredOptions = Object.keys(eventMap).filter((key) =>
        eventMap[key].toLowerCase().includes(input.toLowerCase())
      );
      setLatest(input);
      setOptions(filteredOptions);
    };

    const nameChange = (value) => {
      if (value === undefined) {
        setPickid(-1);
        searchForm.setFieldsValue({ name: '' });
      } else {
        setPickid(value);
        searchForm.setFieldsValue({ name: eventMap[value] });
      }
    };

    const handleBlur = () => {
      const values = searchForm.getFieldsValue();

      // 選單長度不為0 && 當前輸入框有文字，失焦時自動選擇選單的第一個選項
      if (options.length !== 0 && latest !== '') {
        setPickid(options[options.length - 1]);
        searchForm.setFieldsValue({
          name: eventMap[options[options.length - 1]],
        });
      }
      // 如果沒有選擇選項 && 選單長度不為0 && 當前輸入框沒有文字
      else if (
        (values.name === '' && options.length !== 0 && latest === '') ||
        // 選單長度為0 && 當前輸入框有文字
        (options.length === 0 && latest !== '')
      ) {
        setPickid(-1);
        searchForm.setFieldsValue({ name: '' });
      }
    };

    const DownloadSample = () => {
      Modal.confirm({
        maskClosable: true, // 點擊蒙層是否允許關閉
        title: '確定下載範例？',
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn loading={loading} />
          </>
        ),
        okText: '確定',
        cancelText: '取消',
        onOk: async () => {
          httpHandler
            .fileDownload(
              `/marketing_campaign/download_customer_sample`,
              'csv',
              '客群名單範例'
            )
            .then((response) => {
              console.log(response.message);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        },
      });
    };

    return (
      <>
        <Form
          form={searchForm}
          name="searchForm"
          onFinish={searchMarketingCampaignItem}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="活動名稱" name="name">
                <Select
                  showSearch
                  allowClear
                  onChange={nameChange}
                  onBlur={handleBlur}
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {options
                    .slice()
                    .reverse()
                    .map((key) => (
                      <Select.Option key={key} value={key}>
                        {eventMap[key]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<VerticalAlignBottomOutlined />}
                style={{ marginRight: 5 }}
                onClick={() => {
                  DownloadSample();
                }}>
                下載客群範例
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsModalVisible(true);
                }}
                className="button button--register">
                新增
              </Button>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search"
                onClick={() => {
                  updateData();
                }}>
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  // 表格組件
  const DataTable = () => {
    const [isReadMoreModalVisible, setIsReadMoreModalVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const readMore = (text) => {
      const maxLength = 10; // 最大顯示的字元數
      if (text.length > maxLength) {
        return (
          <span>
            {text.substring(0, maxLength)}...
            <a
              onClick={() => {
                setCurrentText(text);
                setIsReadMoreModalVisible(true);
              }}>
              閱讀更多
            </a>
          </span>
        );
      }
      return text;
    };
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '活動名稱',
        dataIndex: 'name',
      },
      {
        title: '操作',
        dataIndex: '',
        width: 100,
        render: (text, record, index) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                icon={<EditOutlined />}
                type="primary"
                className="button--edit"
                style={{ marginRight: 6, width: 85 }}
                disabled={record.status === '2'}
                onClick={() => {
                  setIsModalVisible(true);
                  setIsNew(false);
                  setPickid(record.campaign_id);
                  modalForm.setFieldsValue({
                    id: record.id,
                    name: record.name,
                    customer_name: record.customer_name,
                    description: record.description,
                  });
                }}>
                修改
              </Button>
              {/* <Button
                type="primary"
                style={{ marginTop: 10, marginBottom: 10 }}
                onClick={() => {
                  get_custom_data();
                }}>
                下載客群資料
              </Button> */}
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                className="button--delete"
                disabled={record.status == '2' || record.status == '99'}
                style={{ width: 85 }}
                onClick={() => {
                  handleDelete(record.id);
                }}>
                刪除
              </Button>
            </div>
          );
        },
      },
      {
        title: '客群名稱',
        dataIndex: 'customer_name',
      },
      {
        title: '客群描述',
        dataIndex: 'description',
        width: 150,
        render: (text) => {
          if (text) {
            return readMore(text);
          }
        },
      },
      {
        title: '修改時間',
        dataIndex: 'update_time',
      },
      {
        title: '修改人員',
        dataIndex: 'update_user',
      },
      {
        title: '建立時間',
        dataIndex: 'create_time',
      },
      {
        title: '建立人員',
        dataIndex: 'create_user',
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title="完整內容"
          open={isReadMoreModalVisible}
          onOk={() => setIsReadMoreModalVisible(false)}
          onCancel={() => setIsReadMoreModalVisible(false)}>
          <Typography>
            <Paragraph>
              <pre>{currentText}</pre>
            </Paragraph>
          </Typography>
        </Modal>
      </>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <MarketingCampaignModal
          searchMarketingCampaignItem={searchMarketingCampaignItem}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
          setPickid={setPickid}
          pickid={pickid}
        />
      ),
    },
  ];

  return (
    <div>
      <PageHeader functionName={'target_customer'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

// 將時、分、秒都設定為0
const toExactDate = (date) => {
  const d = new Date(date);
  d.setHours(8, 0, 0, 0); // Set hours, minutes, seconds and milliseconds to 0
  return d;
};

// 共用的Modal
const MarketingCampaignModal = (props) => {
  const httpHandler = useContext(HttpHandlerContext);
  const {
    searchMarketingCampaignItem,
    isModalVisible,
    setIsModalVisible,
    modalForm,
    isNew,
    setPickid,
    pickid,
  } = props;
  const [loading, setLoading] = useState(false);
  const handleModalSubmit = async () => {
    try {
      const values = await modalForm.validateFields();
      if (isNew) {
        const confirmed = await window.antdConfirm(
          `確定${isNew ? '新增' : '修改'}客群嗎？`
        );
        if (!confirmed) {
          return;
        }
      } else {
        if (values.target_customer !== undefined) {
          const warning = await window.antdConfirm(
            `⚠️注意：上傳的名單將替換現有名單!`
          );
          if (!warning) {
            return;
          }
        } else {
          const warning = await window.antdConfirm(
            `確定${isNew ? '新增' : '修改'}客群嗎？`
          );
          if (!warning) {
            return;
          }
        }
      }

      setLoading(true);
      // 根據isNew判斷是新增還是修改
      let requestPromise;
      const msg = isNew ? '新增成功' : '修改成功';
      const formData = new FormData();

      formData.append('ids', pickid);
      formData.append('customer_name', values.customer_name);
      if (
        values.description !== null &&
        values.description !== '' &&
        values.description !== undefined
      ) {
        formData.append('description', values.description);
      }

      if (values.target_customer !== undefined) {
        values.target_customer.fileList.forEach((file) => {
          formData.append('file', file['originFileObj']);
        });
      }

      if (isNew === true) {
        requestPromise = httpHandler.post('/target_customer/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data; charset=UTF-8',
          },
        });
      } else {
        requestPromise = httpHandler.post(
          `/target_customer/${values.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data; charset=UTF-8',
            },
          }
        );
      }

      requestPromise
        .then((response) => {
          if (response.success) {
            window.antdNotify('成功', msg, 'success');
            setIsModalVisible(false);
            searchMarketingCampaignItem();
            modalForm.resetFields();
            setLoading(false);
          } else {
            setLoading(false);
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = () => {
    setPickid(-1);
    modalForm.resetFields();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title={isNew ? '新增客群' : '修改客群'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}
      okButtonProps={{ loading: loading }}>
      <ModalForm modalForm={modalForm} isNew={isNew} setPickid={setPickid} />
    </Modal>
  );
};
// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm, isNew, setPickid } = props;
  const fileList = useRef(null);
  const [name, setName] = useState('');
  const [items, setItems] = useState(customerMap);
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const nameChange = (value) => {
    setPickid(value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleChange = ({ file, fileList: newfile }) => {
    fileList.current = file;
  };

  const beforeUpload = (file) => {
    file = file['file']['originFileObj'];
    return file;
  };

  return (
    <Form form={modalForm}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="活動名稱"
        name="name"
        rules={[{ required: true, message: '請選擇活動名稱!' }]}>
        <Select
          showSearch
          onChange={nameChange}
          dropdownStyle={{ height: '166px', overflow: 'auto' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {Object.keys(eventMap)
            .slice()
            .reverse()
            .map((key) => {
              if (eventStatus[key] !== 98 && eventStatus[key] !== 99) {
                return (
                  <Select.Option key={key} value={key}>
                    {eventMap[key]}
                  </Select.Option>
                );
              }
              return null;
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="客群名稱"
        name="customer_name"
        rules={[{ required: true, message: '請輸入客群名稱' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="客群描述" name="description" labelCol={{ span: 4 }}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="上傳客群"
        name="target_customer"
        rules={isNew ? [{ required: true, message: '請輸入客群名稱' }] : []}
        labelCol={{ span: 4 }}>
        <Upload
          accept=".csv"
          beforeUpload={beforeUpload}
          onChange={handleChange}
          maxCount={1}>
          <Button icon={<PlusOutlined />}>上傳</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

// 驗證props
MarketingCampaignModal.propTypes = {
  searchMarketingCampaignItem: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
};
ModalForm.propTypes = {
  modalForm: PropTypes.object,
};
export default TargetCustomer;
