import React from "react";
import { Breadcrumb, Row, Col } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getFunctionPath } from "../pages/basic/home/menuItem";

const PageHeader = ({ functionName, children }) => {
  const pagePath = getFunctionPath(functionName);
  const page = pagePath[pagePath.length - 1];
  const breadcrumb = pagePath.map((item, index) => {
    if (index === 0) {
      return {
        title: <Link to={"/home"}>{item.description}</Link>,
      };
    } else if (item.url) {
      return {
        title: <Link to={item.url}>{item.description}</Link>,
      };
    } else {
      return {
        title: item.description,
      };
    }
  });
  return (
    <div className="page-header">
      <Breadcrumb className="page-code" items={breadcrumb} />
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <span className="page-header-span">{page.description}</span>
        </Col>
        <Col>{children ? children : <></>}</Col>
      </Row>
    </div>
  );
};
PageHeader.propTypes = {
  functionName: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default PageHeader;
