import React from 'react';
import { Button, Row, Col, Typography, Divider } from 'antd';
import LoginBgImage from '../../images/login-bg.jpeg';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
const { Title } = Typography;

const Login = () => {
  const { instance } = useMsal();
  //按下登入按鈕會執行的邏輯
  const handleLogin = async () => {
    localStorage.setItem('userLoggedIn', 'true');
    // 寫入當前台灣時間
    localStorage.setItem(
      'loginTime',
      new Date().toLocaleString('zh-TW', { timeZone: 'Asia/Taipei' })
    );

    await instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${LoginBgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100vh',
        }}>
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <Col
            xs={22}
            sm={16}
            md={12}
            lg={10}
            xl={10}
            style={{
              background: 'rgba(0, 0, 0, 0.2)',
              padding: '24px',
              borderRadius: '20px',
              boxShadow: '0 0 15px 1px #000000',
            }}>
            <Title level={1} style={{ color: '#fff', textAlign: 'center' }}>
              <div>個人化廣宣管理平台</div>
            </Title>
            <Divider style={{ background: '#fff' }} />
            <Button type="primary" onClick={handleLogin} size="large" block>
              請使用公司Azure帳號進行登入
            </Button>
          </Col>
        </Row>
      </div>
      <>
        <div
          style={{
            position: 'fixed',
            right: '20px',
            bottom: '20px',
            color: '#fff',
          }}>
          Version {process.env.REACT_APP_VERSION}
        </div>
      </>
    </>
  );
};

export default Login;
