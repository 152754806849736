import { React, useState, useEffect, useContext } from 'react';
import { Button, Modal, Form, Input, Divider, Row, Col, Tree } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { HttpHandlerContext } from '../../shared/MsalHttpHandler';
import { FunctionListHandler } from '../../shared/functionListHandler';
import PropTypes from 'prop-types';

const modeRef = {
  add: {
    text: '新增',
    icon: <UserAddOutlined />,
    class: 'register',
    api: '/role_maintenance/add_role',
  },
  edit: {
    text: '編輯',
    icon: <EditOutlined />,
    class: 'edit',
    api: '/role_maintenance/edit_role',
  },
};
const formDataEmpty = {
  id: null,
  name: '',
  description: '',
  function_list: '',
};

const Role = ({ modeType, formData, okFunc, okFuncProps }) => {
  formData = formData || formDataEmpty;
  const httpHandler = useContext(HttpHandlerContext);

  const functionListHandler = new FunctionListHandler(httpHandler);
  const [functionList, setFunctionList] = useState(formData.function_list_opts);
  const functionTree = functionListHandler.convertToTreeData(functionList);

  const mode = modeRef[modeType];
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  let funcs = [];
  if (formData.function_list) {
    funcs = formData.function_list.split(',').map((item) => {
      return item;
    });
  }
  const [checkedKeys, setCheckedKeys] = useState(funcs);

  const onFinish = async (values) => {
    if (checkedKeys.length === 0) {
      window.antdNotify('錯誤', '請選擇 "功能權限" 至少一項！', 'fail');
      return;
    } else {
      values.name = values.name.trim();
      values.function_list = functionListHandler.filterChildrenKeys(
        functionTree,
        checkedKeys
      );
    }

    const confirmed = await window.antdConfirm(`確定${mode.text}角色嗎？`);
    if (confirmed) {
      setLoading(true);
      httpHandler
        .post(mode.api, values)
        .then((response) => {
          if (response.success) {
            window.antdNotify(
              `${mode.text}成功！`,
              response.message,
              'success'
            );
            setVisible(false);
            form.setFieldsValue(formDataEmpty);
            okFunc(okFuncProps);
          } else {
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  useEffect(() => {
    form.setFieldsValue(formData);
    setFunctionList(formData.function_list_opts);
  }, [form, formData]);

  return (
    <>
      <Button
        icon={mode.icon}
        type="primary"
        className={`button button--${mode.class}`}
        onClick={() => setVisible(true)}>
        {mode.text}
      </Button>
      <Modal
        forceRender
        title={
          <>
            {mode.icon}
            &nbsp; {mode.text}角色
          </>
        }
        className="common-modal-lg"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setVisible(false)}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            確定
          </Button>,
        ]}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Divider></Divider>
          <Form.Item label="id" name="id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label="名稱"
                name="name"
                rules={[{ required: true, message: '請輸入名稱!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="描述"
                name="description"
                rules={[{ required: true, message: '請輸入描述!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span={12}>
            功能權限 <span style={{ color: 'red' }}>(至少一項)</span>：
          </Col>
        </Row>
        <Row>
          <Col span={2}></Col>
          <Col span={22}>
            <Tree
              checkable
              defaultExpandAll
              onCheck={onCheck}
              defaultCheckedKeys={funcs}
              treeData={functionTree}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

Role.propTypes = {
  modeType: PropTypes.oneOf(Object.keys(modeRef)).isRequired,
  formData: PropTypes.object,
  okFunc: PropTypes.func,
  okFuncProps: PropTypes.object,
};

export default Role;
